import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import store from './redux/store';
import { resendMail, googlesignin, fblogin, logo, fbloginid, url, orcidlogin, applelogin, appleclientid, gmailclientid,orcidclientid ,redirectUri} from './/api/api';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
ReactDOM.render(
  <GoogleOAuthProvider clientId={gmailclientid}>
<Provider store={store}>

   <App />
</Provider>
</GoogleOAuthProvider>,
  document.getElementById('root')
);



