import Logo from './images/bloomsbury-logo.png';
export const SERVER_URL = "https://bloomsburyprofessional.in/";
export const APP_NAME="Bloomsbury";
export const LOGO=Logo;
export const FbLoginID=712424803526151;
export const AppleClientId="co.novareader.researchpad"
export const GmailClientId="343724709682-l31811f5o4e0l4u02g9jv595ahe07012.apps.googleusercontent.com"
export const OrcidClientId="https://orcid.org/oauth/authorize?client_id=APP-NE6GOPUF09930F9B&response_type=code&scope=/authenticate&redirect_uri=https://mobile.bloomsburyprofessional.in/login"
export const URL="https://mobile.bloomsburyprofessional.in/"
export const AppVersion="7"
export const RedirectUri="https://bloomsburyprofessional.in/api/applelogin"
export const Cover="https://bloomsburyprofessional.in/unsecured/";