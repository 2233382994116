import React, { useState, useEffect } from "react";
import { Col, Row, Button, message, Collapse, Modal, Input, Breadcrumb, Tabs, Typography, Result, Space } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../component/dashboard/header'
import { useHistory, useLocation } from 'react-router-dom'
import { bookcover, bookpath, saveandremovefav, bookdetails, favoriteURL, subscriptionURL, passcodevalidation, serverurl,bookavilable } from '../../api/api';
import { RWebShare } from "react-web-share";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ReadOutlined, ArrowLeftOutlined, FilePdfOutlined, CopyOutlined, BarcodeOutlined, HeartFilled, LoadingOutlined, CalendarOutlined, DownloadOutlined, ShareAltOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";
import './styles.css';
import moment from "moment";
import { FaShoppingCart } from "react-icons/fa";
import PageHader from "../../component/pageheader/pageHeader";

const { TabPane } = Tabs;
const { Title } = Typography;
const { Panel } = Collapse;
const { TextArea } = Input;

const NewDescription = (props) => {
    const location = useLocation();
    const contentid = location.state.contentid;
    const fuzzyid = location.state.fuzzyid
    const isbn = props.match.params.isbn
    const history = useHistory();
    const dispatch = useDispatch();
    const lpp_userCredentials = JSON.parse(localStorage.getItem('lpp_userCredentials'));
    const [description, setdescription] = useState('');
    const [loadings, setloadings] = useState(false);
    const [loading, setloading] = useState(false);
    const [subloading, setsubloading] = useState(false);
    const [likeiconcolor, setlikeiconcolor] = useState('#E8E4E4')
    const [isfavorite, setisfavorite] = useState(false)
    const [book, setbook] = useState({})
    const [isfree, setisfree] = useState(77)
    const [bookaccess, setbookaccess] = useState("77")
    const [issub, setissub] = useState(false)
    const [visible, setVisible] = useState(false);
    const [typevisible, setTypeVisible] = useState(false);
    const [readervisible, setreadervisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');
    const [password, setpassword] = useState("")
    const [ispasswordvalid, setispasswordvalid] = useState(false)
    const [isbtnloading, setsbtnloading] = useState(false)
    const [isuserclickbuy, setuserclickbuy] = useState(false)
    const [authors, setauthors] = useState([])
    const [toc, settoc] = useState()
    const [error, seterror] = useState(false)
    const [format, setformat] = useState('')
    const [pdf, setpdf] = useState(false)
    const [epub, setepub] = useState(false)
    const [rating, setrating] = useState('')
    const [feedback, setfeedback] = useState(' ')
    const [pricing, setpricing] = useState([])
    const [seletedformet, setseletedformet] = useState()
    const [authorBio, setAuthorBio] = useState('')

    useEffect(async () => {
        window.scrollTo(0, 0)
        console.log(lpp_userCredentials)
        if (lpp_userCredentials) {
            await Promise.all([
                getBookdeltais(lpp_userCredentials.apikey),
                getSub(lpp_userCredentials.apikey),
                getFavorite(lpp_userCredentials.apikey),

            ])
        } else {
            getBookdeltaisno()

        }


    }, [])

    const refreash = async () => {
        setbook({})
        setdescription('')
        setbookaccess("77")
        setisfavorite(false)
        setissub(false)
        await Promise.all([
            getBookdeltais(),
            getFavorite(lpp_userCredentials.apikey),
            getSub(lpp_userCredentials.apikey),
        ])
    }

    const getBookdeltais = (apikey) => {
        setloading(true)
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: {
                'apikey': apikey
            }
        };
        fetch(bookdetails + isbn, requestOptions)
            .then(response => response.json())
            .then(result => {
                let getauthors = !result.authors ? "no author" : result.authors.map(item => item.surName+" "+item.givenName)
                setbook(result.content)
                setdescription(result.description)
                setbookaccess(result.content.access)
                setAuthorBio(result.authorBio)
                setpricing(result.contentFormats)
                setauthors(getauthors)
                settoc(result.toc)
                setloading(false)


            })
            .catch(error => {

                seterror(true)
                setloading(false)

            });
    }
    const getBookdeltaisno = () => {
        setloading(true)
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',

        };
        fetch(bookdetails + isbn, requestOptions)
            .then(response => response.json())
            .then(result => {
                let getauthors = !result.authors ? "no author" : result.authors.map(item => item.surName+" "+item.givenName)
                setbook(result.content)
                setdescription(result.description)
                setbookaccess(result.content.access)
                setAuthorBio(result.authorBio)

                setpricing(result.contentFormats)
                setauthors(getauthors)
                settoc(result.toc)
                setloading(false)

            })
            .catch(error => {
                console.log(error)

                seterror(true)
                setloading(false)

            });
    }
    const getFavorite = async (apikey) => {
        let axios = require('axios');
        let config = {
            method: 'get',
            url: favoriteURL,
            headers: {
                'apikey': apikey
            }
        };
        axios(config)
            .then((response) => {

                if (response.data.data.length > 0) {
                    let isValuefound = response.data.data.filter(obj => obj.identifier === isbn);
                    if (isValuefound.length > 0) {
                        setisfavorite(true)
                    } else {
                        setisfavorite(false)
                    }


                } else {
                    setisfavorite(false)

                }

            })
            .catch(function (error) {

            });
    }

    const goTopdf = () => {
        history.push({ pathname: '/read/book/pdf/' + book.elasticId, state: { contentid: contentid, fuzzyid: book.fuzzyId, isbn: isbn, name: book.name, type: "book", isonline: 'yes', cover: book.cover,info:book,auth:authors } })
        setreadervisible(true);
    }

    const goToepub = () => {
        history.push({ pathname: '/read/book/epub/' + book.elasticId, state: { contentid: contentid, isbn: isbn, name: book.name, type: "book", fuzzyid: book.fuzzyId, cover: book.cover,info:book,auth:authors } })
    }

    const addOrRemoveLibrary = (id) => {
        if (lpp_userCredentials) {
        setloadings(true);
        var myHeaders = new Headers();
        myHeaders.append("apikey", lpp_userCredentials.apikey);
        var formdata = new FormData();
        formdata.append("contentId", contentid);
        formdata.append("library", id);
        formdata.append("path", bookpath + isbn);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(saveandremovefav, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (id == 1) {
                    message.success('Book added to your favorite list');
                    setisfavorite(true)
                    setloadings(false);
                } else {
                    message.success('Book Removed from your favorite list');
                    setisfavorite(false)
                    setloadings(false);

                }
            })
            .catch(error => {
                setisfavorite(false)
                setloadings(false);
                message.error('Sorry, something went wrong.');


            });
        }else{
            history.push('/login')
        }
    }

    const content = (item) => (
        <div>
            <p onClick={() => { goToepub(item) }}> <ReadOutlined style={{ fontSize: '15px', marginTop: '10px', color: 'blue', marginRight: '10px' }} />Epub</p>
            <p onClick={() => { goTopdf(item) }}> <FilePdfOutlined style={{ fontSize: '15px', marginTop: '10px', color: 'red', marginRight: '10px' }} />Pdf</p>
        </div>
    );
    const getSub = async (apikey) => {
        setsubloading(true)
        let axios = require('axios');
        let config = {
            method: 'get',
            url: subscriptionURL,
            headers: {
                'apikey': apikey
            }
        };
        axios(config)
            .then((response) => {
                if (response.data.data.length > 0) {
                    console.log("subscription", response.data)
                    let isValuefound = response.data.data.filter(obj => obj.identifier === isbn);
                    if (isValuefound.length > 0) {
                        console.log(isValuefound)
                        setformat(isValuefound[0].format)
                        setsubloading(false)
                        setissub(true)
                    } else {
                        setsubloading(false)
                        setissub(false)
                    }

                } else {
                    setsubloading(false)
                    setissub(false)

                }

            })
            .catch(function (error) {
                console.log(error)
                setsubloading(false)
                setissub(false)
            });
    }

    const showModal = () => {
        setVisible(true);
    };

    const showModalBookType = () => {
        setreadervisible(true);
    }
    const handleOk = () => {
        setConfirmLoading(true);
        if (password.length > 0) {
            setConfirmLoading(true);
            var myHeaders = new Headers();
            myHeaders.append("apikey", lpp_userCredentials.apikey);
            var formdata = new FormData();
            formdata.append("contentId", contentid);
            formdata.append("passcode", password);
            formdata.append("deviceName", "chrome");
            formdata.append("deviceId", "chromeapp");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            fetch(passcodevalidation, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.r == 1) {
                        setConfirmLoading(false);
                        setispasswordvalid(true)
                        setVisible(false)
                        if (seletedformet.format == "EPUB") {
                            goToepub()
                        }
                        if (seletedformet.format == "PDF") {
                            goTopdf()
                        }
                        // if (book.epubExists) {
                        //     history.push({ pathname: '/read/book/epub/' + book.elasticId, state: { contentid: contentid, fuzzyid: fuzzyid, isbn: isbn, name: book.name, type: "book", cover: book.cover } })
                        // } else {
                        //     history.push({ pathname: '/read/book/pdf/' + book.elasticId, state: { contentid: contentid, fuzzyid: fuzzyid, isbn: isbn, name: book.name, type: "book", isonline: 'yes', cover: book.cover } })
                        // }
                    } else {
                        setConfirmLoading(false);

                        message.warning('Enter valid password');
                    }
                })
                .catch(error => {
                    message.warning('Enter valid password');
                    setConfirmLoading(false);
                });


        } else {
            setConfirmLoading(false);
            message.warning('Enter valid password');
        }


    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setVisible(false);
    };
    const handleCancelreader = () => {
        console.log('Clicked cancel button');
        setreadervisible(false);
    };
    const onChange = e => {
        setpassword(e.target.value)
        console.log('Change:', e.target.value);
    };
    const Change = (key) => {
        console.log(key);
    };
    const contentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        e.preventDefault();

        console.log(targetLink.href);
    }
    const GoToreader = (item) => {
        console.log("start reading")
        console.log(format)
        if (bookaccess == 2) {
            setseletedformet(item)
            //goToepub()
            showModal()
        } else if (issub && bookaccess == 0) {
            console.log(item)
            
            //goTopdf()
            if (format == "epub") {
                console.log("bookaccess ", bookaccess)
                goToepub()
            }
            if (format == "webpdf") {
                goTopdf()
            }
            showModalBookType()
        }
        else if (issub || bookaccess == 1) {
            if (item.format == "EPUB") {
                goToepub()
            }
            if (item.format == "PDF") {
                goTopdf()
            }
        }
    }

    const handleChangerate = value => {
        setrating(value)
    };
    const onChangefeed = e => {
        setfeedback(e.target.value)
        console.log('Change:', e.target.value);
    };

    const avilableNotifiction = () => {
        const axios = require('axios');
        let data = JSON.stringify([
            {
                "name": "user",
                "label": "User",
                "value": lpp_userCredentials.displayName
            },
            {
                "name": "email",
                "label": "Email",
                "value": lpp_userCredentials.email
            }
        ]);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: bookavilable,
            headers: {
                'apikey': lpp_userCredentials.apikey,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                message.info('We will inform to you when the book is live.');



            })
            .catch((error) => {
                console.log(error);
            });

    }

    const readui=(item)=>{
        if(item.format=="PAPERBACK"){
            return(
                <h4  onClick={()=>{ window.open(serverurl + "book/isbn/" + book.isbn, "_blank")}}><span className="curren">{item.currency+" "}</span>{item.vat.grossPrice} <FaShoppingCart className="cardicon" /></h4>
        
              )
        }
        if(item.format=="EPUB"){
            if(format.search("epub")>=0){
                return(
                    <h4 ><div className="icons">
                    <ReadOutlined onClick={goToepub} className="readr-icon"/>    </div></h4>
            
                  )
            }else{
                return(
                    <h4 onClick={()=>{ window.open(serverurl + "book/isbn/" + book.isbn, "_blank")}}><span className="curren">{item.currency+" "}</span>{item.vat.grossPrice}  <FaShoppingCart className="cardicon" /></h4>
            
                  )
            }
            
        }
        if(item.format=="PDF"){
            if(format.search("webpdf")>=0){
                return(
                    <h4><div className="icons">
                    <ReadOutlined onClick={goTopdf} className="readr-icon"/></div> </h4>
            
                  )
            }else{
                return(
                    <h4 onClick={()=>{ window.open(serverurl + "book/isbn/" + book.isbn, "_blank")}}><span className="curren">{item.currency+" "}</span>{item.vat.grossPrice}  <FaShoppingCart className="cardicon" /></h4>
            
                  )
            }
            
        }

    
    }

    if (loading || subloading) {
        return (
            <div className="continer">
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

                <PageHader titel="Book"/>

                    <Row className="listcontiner" style={{ "padding": "0px", "margin": "0px 0px 0px 0px", padding: '5px', borderRadius: "5px" }}>
                        <div style={{ width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column',marginTop:'15px' }}>
                            <Skeleton width={280} height={300} />
                            <div style={{ height: "10px", width: '100%' }}></div>
                            <Skeleton width={230} height={25} />
                            <div style={{ height: "10px", width: '100%' }}></div>

                            <Skeleton width={300} height={10} count={5} />
                            <div style={{ height: "10px", width: '100%' }}></div>
                            <Skeleton width={230} height={25} />
                            <div style={{ height: "5px", width: '100%' }}></div>

                            <Skeleton width={230} height={25} />



                        </div>


                    </Row>
                </Col>
            </div>
        )
    }

    if (error) {
        return (
            <div className="continer" >
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

                <PageHader titel="Book"/>

                    <Result
                        status="500"
                        title="500"
                        subTitle="Sorry, something went wrong."
                        extra={<Button onClick={() => { history.goBack() }} type="primary">Back</Button>}
                    />
                </Col>
            </div>
        )
    }

    if(issub) {return(
        <div className="continer">
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

            <PageHader titel="Book"/>

                <Row className="listcontiner" style={{ "padding": "0px", "margin": "0px 0px 0px 0px", padding: '5px', borderRadius: "5px" }}>
                    <div style={{ width: '100%',marginTop:'15px'}}>
                        <div style={{ width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            <img className="dcover" src={bookcover + book.fuzzyId + "/" + book.cover} />
                        </div>
                        <div style={{ display: 'flex', marginBottom: '30px', marginTop: '5px', marginLeft: '10px', marginRight: '10px', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ textAlign: 'center', fontSize: '15px', letterSpacing: '1px', fontFamily: 'var(--font)', color: '#000', fontWeight: 'bold' }}>{book.name}</div>
                            {authors.length>1?<div style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'var(--font)', color: '#273746' }}><span style={{ fontFamily: 'var(--font)', color: '#ff7600', fontSize: '13px' }}>Authors: </span>{authors.toString().replaceAll(",", ", ")}</div>:
                           <div style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'var(--font)', color: '#273746' }}><span style={{ fontFamily: 'var(--font)', color: '#ff7600', fontSize: '13px' }}>Author: </span>{authors.toString().replaceAll(",", ", ")}</div>
                           } 
                          {book.pubDate &&<div style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'var(--font)', color: '#273746' }}><span style={{ fontFamily: 'var(--font)', color: '#ff7600', fontSize: '13px' }}>Publication Date: </span>{moment(book.pubDate).format("MMM Do YY")}</div>}
                            {book.pages ==0 ? "": <div style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'var(--font)', color: '#273746' }}><span style={{ fontFamily: 'var(--font)', color: '#ff7600', fontSize: '13px' }}>Pages: </span>{book.pages}</div> }                          
                            <div style={{ width: '50%', height: '40px', marginTop: '10px', fontFamily: 'var(--font)', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', fontSize: '20px' }}>{loadings ? <LoadingOutlined style={{ color: 'red' }} /> : <HeartFilled style={{ color: isfavorite ? "red" : "#273746" }} onClick={() => { if (isfavorite) { addOrRemoveLibrary(0) } else { addOrRemoveLibrary(1) } }} />}
                                <RWebShare
                                    data={{
                                        text: book.name,
                                        url: serverurl + "book/isbn/" + book.isbn,
                                        title: "Share",
                                    }}
                                    onClick={() => console.log("shared successfully!")}>
                                    <ShareAltOutlined /></RWebShare></div>

                            {/* <div className="bookinfo">
                                        <div className="info-box">
                                            <p className="infotitel">Paper Back</p>
                                              <CopyOutlined  className="info-icon"/>                                            
                                              <p className="info-food">280 page</p>

                                        </div>
                                        <div className="with1"></div>
                                        <div className="info-box">
                                            <p className="infotitel">Date</p>
                                            <CalendarOutlined  className="info-icon"/>                                            
                                            <p className="info-food">12-02-2016</p>
                                        </div>
                                        <div className="with1"></div>

                                        <div className="info-box">
                                            <p className="infotitel">ISBN</p>
                                            <BarcodeOutlined className="info-icon"/>                                            
                                            <p className="info-food">8764567908</p>
                                        </div>

                                    </div> */}

                            <>
                            <Button onClick={GoToreader}>Start Reading</Button>
                                

                            </>


                        </div>

                        <Collapse style={{ marginBottom: '30px' }} expandIconPosition={["right"]} onChange={Change} >
                            <Panel header="About Book" key="1" className="panal">
                                <p style={{ padding: '10px', borderRadius: '5px', fontFamily: 'var(--font)', color: '#273746' }} dangerouslySetInnerHTML={{ __html: description }} />
                            </Panel>
                         {/* {toc=="<html>\n <head></head>\n <body></body>\n</html>"?null:<Panel header="Table of Content" key="2" className="panal">
                                <div style={{ padding: '10px', borderRadius: '5px', fontFamily: 'var(--font)', color: '#273746' }} onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: toc }} />
                            </Panel>} */}
                            {authorBio && <Panel header="Author Bio" key="3" className="panal">
                                <div style={{ padding: '10px', borderRadius: '5px', fontFamily: 'var(--font)', color: '#273746' }} onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: authorBio }} />
                            </Panel>}
                            {/* <Panel header="User Review" key="3" className="panal">
                                <div className="comment">
                                 Rating
                                <Rate   onChange={handleChangerate}  value={rating} />
                                User Review
                                <TextArea onChange={onChangefeed} value={feedback} placeholder="Review" showCount maxLength={100} style={{marginTop:'5px'}} rows={3} />
                                <div className="submit-btn">
                                    <p>Submit</p>
                                </div>
                                </div>
                               
                            </Panel> */}

                        </Collapse>
                    </div>
                    
                    <Modal
                        title={<Title style={{ fontFamily: 'var(--font)', letterSpacing: '1px', fontWeight: 'bold', fontSize: '15px' }}>Select Reader</Title>}
                        visible={readervisible}
                        footer={null}
                        onCancel={handleCancelreader}>
                        <div>
                            <p style={{ fontFamily: 'var(--font)', letterSpacing: '1px' }} onClick={() => { goToepub() }}> <ReadOutlined style={{ fontSize: '15px', marginTop: '10px', color: 'blue', marginRight: '10px', fontFamily: 'var(--font)', letterSpacing: '1px' }} />Epub</p>
                            <p style={{ fontFamily: 'var(--font)', letterSpacing: '1px' }} onClick={() => { goTopdf() }}> <FilePdfOutlined style={{ fontSize: '15px', marginTop: '10px', color: 'red', marginRight: '10px', fontFamily: 'var(--font)', letterSpacing: '1px' }} />Pdf</p>
                        </div>
                    </Modal>
                    <Modal
                        title={<Title style={{ fontFamily: 'var(--font)', letterSpacing: '1px', fontWeight: 'bold', fontSize: '15px' }}>Enter Passcode</Title>}
                        visible={visible}
                        onOk={handleOk}
                        confirmLoading={confirmLoading}
                        onCancel={handleCancel}>
                        <Input.Password placeholder="Passcode" value={password} onChange={onChange} />
                    </Modal>
                </Row>
            </Col>
        </div>
        
    )}
    return (

        <div className="continer">
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

            <PageHader titel="Book"/>

                <Row className="listcontiner" style={{ "padding": "0px", "margin": "0px 0px 0px 0px", padding: '5px', borderRadius: "5px" }}>
                    <div style={{ width: '100%',marginTop:'15px'}}>
                        <div style={{ width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            <img className="dcover" src={bookcover + book.fuzzyId + "/" + book.cover} />
                        </div>
                        <div style={{ display: 'flex', marginBottom: '30px', marginTop: '5px', marginLeft: '10px', marginRight: '10px', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ textAlign: 'center', fontSize: '15px', letterSpacing: '1px', fontFamily: 'var(--font)', color: '#000', fontWeight: 'bold' }}>{book.name}</div>
                            {authors.length>1?<div style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'var(--font)', color: '#273746' }}><span style={{ fontFamily: 'var(--font)', color: '#ff7600', fontSize: '13px' }}>Authors: </span>{authors.toString().replaceAll(",", ", ")}</div>:
                           <div style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'var(--font)', color: '#273746' }}><span style={{ fontFamily: 'var(--font)', color: '#ff7600', fontSize: '13px' }}>Author: </span>{authors.toString().replaceAll(",", ", ")}</div>
                           } 
                          {book.pubDate &&<div style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'var(--font)', color: '#273746' }}><span style={{ fontFamily: 'var(--font)', color: '#ff7600', fontSize: '13px' }}>Publication Date: </span>{moment(book.pubDate).format("MMM Do YY")}</div>}
                            {/* {book.pages &&<div style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'var(--font)', color: '#273746' }}><span style={{ fontFamily: 'var(--font)', color: '#ff7600', fontSize: '13px' }}>Pages: </span>{book.pages}</div> }                           */}
                            <div style={{ width: '50%', height: '40px', marginTop: '10px', fontFamily: 'var(--font)', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', fontSize: '20px' }}>{loadings ? <LoadingOutlined style={{ color: 'red' }} /> : <HeartFilled style={{ color: isfavorite ? "red" : "#273746" }} onClick={() => { if (isfavorite) { addOrRemoveLibrary(0) } else { addOrRemoveLibrary(1) } }} />}
                                <RWebShare
                                    data={{
                                        text: book.name,
                                        url: serverurl + "book/isbn/" + book.isbn,
                                        title: "Share",
                                    }}
                                    onClick={() => console.log("shared successfully!")}>
                                    <ShareAltOutlined /></RWebShare></div>

                            {/* <div className="bookinfo">
                                        <div className="info-box">
                                            <p className="infotitel">Paper Back</p>
                                              <CopyOutlined  className="info-icon"/>                                            
                                              <p className="info-food">280 page</p>

                                        </div>
                                        <div className="with1"></div>
                                        <div className="info-box">
                                            <p className="infotitel">Date</p>
                                            <CalendarOutlined  className="info-icon"/>                                            
                                            <p className="info-food">12-02-2016</p>
                                        </div>
                                        <div className="with1"></div>

                                        <div className="info-box">
                                            <p className="infotitel">ISBN</p>
                                            <BarcodeOutlined className="info-icon"/>                                            
                                            <p className="info-food">8764567908</p>
                                        </div>

                                    </div> */}

                            <>
                            
                                {pricing.map((item) => {
                                    return (
                                        <>
                                            <div className="reader-card">
                                                <div className="reader-card-info">
                                                    <h3>{item.displayName}</h3>
                                                    <p><span>ISBN </span>{item.isbn}</p>
                                                </div>
                                                {item.onixStatus == '04' && <div className="icon-continer">
                                                    {readui(item)}
                                                </div>}
                                                {item.onixStatus == '02' || item.onixStatus == '02a' ? <div className="icon-continer">
                                                    <p className="notifibtn" onClick={()=>{if(lpp_userCredentials){avilableNotifiction()}else{history.push('/login')}}}>Notify when available</p>
                                                </div>:null}
                                            </div>
                                        </>)
                                })}

                            </>


                        </div>

                        <Collapse style={{ marginBottom: '30px' }} expandIconPosition={["right"]} onChange={Change} >
                            <Panel header="About Book" key="1" className="panal">
                                <p style={{ padding: '10px', borderRadius: '5px', fontFamily: 'var(--font)', color: '#273746' }} dangerouslySetInnerHTML={{ __html: description }} />
                            </Panel>
                         {/* {toc=="<html>\n <head></head>\n <body></body>\n</html>"?null:<Panel header="Table of Content" key="2" className="panal">
                                <div style={{ padding: '10px', borderRadius: '5px', fontFamily: 'var(--font)', color: '#273746' }} onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: toc }} />
                            </Panel>} */}
                            {authorBio && <Panel header="Author Bio" key="3" className="panal">
                                <div style={{ padding: '10px', borderRadius: '5px', fontFamily: 'var(--font)', color: '#273746' }} onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: authorBio }} />
                            </Panel>}
                            {/* <Panel header="User Review" key="3" className="panal">
                                <div className="comment">
                                 Rating
                                <Rate   onChange={handleChangerate}  value={rating} />
                                User Review
                                <TextArea onChange={onChangefeed} value={feedback} placeholder="Review" showCount maxLength={100} style={{marginTop:'5px'}} rows={3} />
                                <div className="submit-btn">
                                    <p>Submit</p>
                                </div>
                                </div>
                               
                            </Panel> */}

                        </Collapse>
                    </div>
                    
                    <Modal
                        title={<Title style={{ fontFamily: 'var(--font)', letterSpacing: '1px', fontWeight: 'bold', fontSize: '15px' }}>Select Reader</Title>}
                        visible={readervisible}
                        footer={null}
                        onCancel={handleCancelreader}>
                        <div>
                            <p style={{ fontFamily: 'var(--font)', letterSpacing: '1px' }} onClick={() => { goToepub() }}> <ReadOutlined style={{ fontSize: '15px', marginTop: '10px', color: 'blue', marginRight: '10px', fontFamily: 'var(--font)', letterSpacing: '1px' }} />Epub</p>
                            <p style={{ fontFamily: 'var(--font)', letterSpacing: '1px' }} onClick={() => { goTopdf() }}> <FilePdfOutlined style={{ fontSize: '15px', marginTop: '10px', color: 'red', marginRight: '10px', fontFamily: 'var(--font)', letterSpacing: '1px' }} />Pdf</p>
                        </div>
                    </Modal>
                    <Modal
                        title={<Title style={{ fontFamily: 'var(--font)', letterSpacing: '1px', fontWeight: 'bold', fontSize: '15px' }}>Enter Passcode</Title>}
                        visible={visible}
                        onOk={handleOk}
                        confirmLoading={confirmLoading}
                        onCancel={handleCancel}>
                        <Input.Password placeholder="Passcode" value={password} onChange={onChange} />
                    </Modal>
                </Row>
            </Col>
        </div>
    );

}

export default NewDescription;